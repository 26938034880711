import {useEffect, useMemo, useState} from 'react';
import { UserTenantModel } from '../model/UserInfo.model';
import { TokenHelper } from 'common/helpers/auth/token-helper';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
 import { AuthService } from 'common/helpers/auth/auth.service';
import { AuthToken } from 'common/helpers/auth/auth-token';
import { toast } from 'react-toastify';
import { useLoading } from 'common/loading/loading.hook';
import { useNavigate, useLocation } from 'react-router-dom';
import { MenuComponent } from 'assets/ts/components';
import {UserProfileService} from "../../../../../../app/user-profile/service/user-profile.service";
import {useTranslation} from "react-i18next";

interface TenantItemProps {
  tenant?: UserTenantModel;
  className?: string;
}

const TenantItem = ({ tenant, className, ...otherpProps }: TenantItemProps) => (
  <div className={clsx('d-flex align-items-center', className)} {...otherpProps}>
    {/* <div className="bi bi-building-fill fs-14" style={{color: tenant?.color}} /> */}
    <div className="badge badge-circle badge-sm" style={{backgroundColor: tenant?.color }}></div>
    <span className="fw-bolder fs-7 ms-2">{tenant?.name}</span>
  </div>
);

export const TenantSelector = ({ iconWrapperClass = '' }) => {
  const { t } = useTranslation();
  const { isLoading } = useLoading();
  const [userTenant, setUserTenant] = useState<UserTenantModel>();
  const [availableTenants, setAvailableTenants] = useState<UserTenantModel[]>([]);
  const hasMoreTenants = availableTenants.length > 0;

  const token = AuthToken.getToken();

  const switchToTenant = async (id: string) => {
    try {
      isLoading(true);
      await AuthService.switchToTenant(id);
      window.location.reload();
      isLoading(false);
    } finally {
    }
  };

  useEffect(() => {
    (async () => {
      const tenantId = new TokenHelper().GetCurrentTenant();
      const userId = new TokenHelper().GetId();
      
      const response = await UserProfileService.getUserTenants(userId);
      if (!response?.data)
        return;
      const tenants: UserTenantModel[] = response.data;

      const tenant = tenants.find((i) => i.id === tenantId);
      const availableTenants = tenants.filter((t) => t.id !== tenantId);
      
      setUserTenant(tenant);
      setAvailableTenants(availableTenants);
    })();
  }, [token]);

  return (
    <>
      <div
        className={clsx('btn btn-bg-light btn-active-secondary d-flex align-items-center', iconWrapperClass, hasMoreTenants && 'cursor-pointer')}
        data-kt-menu-trigger={clsx(hasMoreTenants && "click")}
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <TenantItem tenant={userTenant} />
        {hasMoreTenants && (
            <div className="fas fw-bolder bi bi-chevron-down fs-4 ms-2"/>
        )}
      </div>
      <div
          className={clsx("menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary py-4 fs-6 w-200px")}
        data-kt-menu="true"
      >
        <div className="menu-item px-5 py-2">{t('topbar.tenantSelector.availableTenants')}</div>
        {availableTenants.map((t) => (
          <div key={t.id} onClick={() => switchToTenant(t.id)} className="menu-item">
            <div className="menu-link px-5 py-2">
              <TenantItem tenant={t} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
