import { http } from 'common/helpers/axios/axios-helper';
import { StatusFilter } from 'common/types/status-filter.model';
import { MachineActivityViewModel } from 'app/machine-activity/model/machine-activity.model';
import { MachineModel as Model } from '../model/machine.model';

const basePath = '/machine';

async function getDataList(status: StatusFilter) {
	const queryParams = [];

	if (status !== undefined && status !== '') {
		queryParams.push(`IsActive=${status}`);
	}

	const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';
	return await http.get<Model[]>(`${basePath}${queryString}`);
}

async function getDataById(id: any) {
	return await http.get<Model>(`${basePath}/${id}`);
}

async function getMachineActivity() {
	return await http.get<MachineActivityViewModel[]>(`${basePath}/activity`);
}

async function postData(data: Model) {
	return await http.post<Model>(`${basePath}`, data);
}

async function putData(data: Model) {
	return await http.put<Model>(`${basePath}/${data.id}`, data);
}

async function deleteDataById(id: any) {
	return await http.del(`${basePath}/${id}`);
}

export const MachineService = {
	getDataList,
	getDataById,
	getMachineActivity,
	postData,
	putData,
	deleteDataById
};
