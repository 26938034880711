import { http } from 'common/helpers/axios/axios-helper';
import {
	ActiveDirectorySettingsModel,
	PasswordPolicySettingsModel,
	AuthenticationMethodSettingsModel,
	GeneralSettingsModel,
	PresetsModel,
	SecurityPolicySettingsModel,
	SMTPSettingsModel,
	UploadDirectorySettingsModel
} from '../model/general-settings.model';

const basePath = '/Settings';

async function getGeneralSettings() {
	return await http.get<GeneralSettingsModel>(basePath);
}

async function getSecuritySettings(refresh: boolean) {
	return await http.get<SecurityPolicySettingsModel>(`${basePath}/security/${refresh}`);
}

async function putSecuritySettings(data: SecurityPolicySettingsModel) {
	return await http.put(`${basePath}/security`, data);
}

async function getPasswordSettings() {
	return await http.get<PasswordPolicySettingsModel>(`${basePath}/password`);
}

async function getSMTPSettings() {
	return await http.get<SMTPSettingsModel>(`${basePath}/smtp`);
}

async function putSMTPSettings(data: SMTPSettingsModel) {
	return await http.put(`${basePath}/smtp`, data);
}

async function getUploadSettings() {
	return await http.get<UploadDirectorySettingsModel>(`${basePath}/upload`);
}

async function putUploadSettings(data: UploadDirectorySettingsModel) {
	return await http.put(`${basePath}/upload`, data);
}

async function getAuthTypeSettings() {
	return await http.get<AuthenticationMethodSettingsModel>(`${basePath}/auth`);
}

async function putAuthTypeSettings(data: AuthenticationMethodSettingsModel) {
	return await http.put(`${basePath}/auth`, data);
}

async function getADSettings() {
	return await http.get<ActiveDirectorySettingsModel>(`${basePath}/ad`);
}

async function putADSettings(data: ActiveDirectorySettingsModel) {
	return await http.put(`${basePath}/ad`, data);
}

async function getPresets() {
	return await http.get<PresetsModel>(`${basePath}/presets`);
}

async function putPresets(data: PresetsModel) {
	return await http.put(`${basePath}/presets`, data);
}

export const GeneralSettingsService = {
	getGeneralSettings,
	getSecuritySettings,
	putSecuritySettings,
	getSMTPSettings,
	putSMTPSettings,
	getUploadSettings,
	putUploadSettings,
	getADSettings,
	putADSettings,
	getAuthTypeSettings,
	putAuthTypeSettings,
	getPresets,
	putPresets,
	getPasswordSettings
};
