import i18next from 'i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import es from '../../i18n/es-es.json';
import { ChangePasswordModel as Model } from '../../model/user-management.model';
import { UserManagementService as Service } from '../../services/user-management.service';
import { Modal } from 'react-bootstrap';
import ITSVG from 'common/helpers/ITSVG';
import closeModalIcon from 'assets/images/icons/close.svg'
import SaveButton from 'common/partials/SaveButton';
import { GeneralSettingsService } from 'app/general-settings/services/general-settings.service';
import { PasswordPolicySettingsModel } from 'app/general-settings/model/general-settings.model';

interface ChangePasswordModalProps {
	open: boolean;
	userName: string;
	name: string;
	id: number;
	handleClose: () => void;
}

export function ChangePassword(props: ChangePasswordModalProps) {
	i18next.addResourceBundle('us', 'translation', en);
	i18next.addResourceBundle('br', 'translation', pt);
	i18next.addResourceBundle('es', 'translation', es);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const [passwordSettings, setPasswordSettings] = useState<PasswordPolicySettingsModel>({} as PasswordPolicySettingsModel);

	let initialData: Model = {
		userName: props.userName,
		oldPassword: '',
		newPassword: '',
		confirmNewPassword: '',
	};

	const [data, setData] = useState<Model>(initialData);

	const getFormSchema = () => {

		let newPassword = Yup.string().required(t('crud.validators.requiredField'));
		if (passwordSettings.passwordRequireDigit)
			newPassword = newPassword.matches(/\d/, t('crud.validators.includeADigit'));
		if (passwordSettings.passwordRequireLowercase)
			newPassword = newPassword.matches(/[a-z]/, t('crud.validators.includeLowercase'));
		if (passwordSettings.passwordRequireUppercase)
			newPassword = newPassword.matches(/[A-Z]/, t('crud.validators.includeUppercase'));
		if (passwordSettings.passwordRequireNonLetterOrDigit)
			newPassword = newPassword.matches(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, t('crud.validators.includeSpecialChar'));
		if (passwordSettings.passwordRequiredMinimumLength) {
			const min = passwordSettings.passwordRequiredMinimumLength;
			newPassword = newPassword.min(min, t('crud.validators.hasMinLength', { n: min }));
		}

		const confirmNewPassword = Yup.string().required(t('crud.validators.requiredField'))
			.oneOf([Yup.ref('newPassword')], t('crud.validators.passwordMatch'));

		return Yup.object({
			oldPassword: Yup.string().required('crud.validators.requiredField'),
			newPassword: newPassword,
			confirmNewPassword: confirmNewPassword
		});
	}

	const formik = useFormik<Model>({
		enableReinitialize: true,
		initialValues: data,
		validationSchema: getFormSchema(),
		onSubmit: async (values: any) => {
			console.log("Submit called ");
			setIsLoading(true);
			const body: Model = {
				userName: props.userName,
				oldPassword: values.oldPassword,
				newPassword: values.newPassword,
				confirmNewPassword: values.confirmNewPassword,
			};

			const response = await Service.changePasswordUser(body);
			if (response && !response.hasErrors) {
				toast.success(`${t('crud.update.successMessage')}`);
				setData(response.data);
				props.handleClose();
			} else if (response && response.errorCode) {
				toast.error(`${t(`error.${response.errorCode}`)}`);
			}
			else {
				toast.error(`${t('crud.update.errorMessage')}`);
			}
			setIsLoading(false);
		}
	});


	const fetchPasswordSettings = async () => {
		try {
			setIsLoading(true);
			const response = await GeneralSettingsService.getPasswordSettings();
			if (response && !response.hasErrors)
				setPasswordSettings(response.data);
		} catch (err) {
			toast.error(`${t('crud.read.errorMessage')}`);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchPasswordSettings();
	}, []);

	return (
		<Modal
			show={props.open}
			onHide={props.handleClose}
			dialogClassName='modal-dialog-centered mw-600px h-auto'
		>
			<div className='modal-content'>
				<div className='modal-header'>
					<h3>{t('form.passwordComponent.title')}</h3>
					<div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.handleClose}>
						<ITSVG path={closeModalIcon} className='svg-icon-1' />
					</div>
				</div>
				<form onSubmit={formik.handleSubmit}>
					<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
						<div className="row">
							<div className="col-12 mb-10">
								<label className="required form-label">{t('form.passwordComponent.oldPassword')}</label>
								<input
									type="password"
									name="oldPassword"
									className="form-control form-control-lg form-control-solid"
									placeholder={t('form.passwordComponent.messages.oldPassword').toString()}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								{formik.touched.oldPassword && formik.errors.oldPassword && (
									<div className="mt-3 text-danger fw-bold">
										<span role="alert">{t(formik.errors.oldPassword)}</span>
									</div>
								)}
							</div>
							<div className="col-12 mb-10">
								<label className="required form-label">{t('form.passwordComponent.newPassword')}</label>
								<input
									type="password"
									name="newPassword"
									className="form-control form-control-lg form-control-solid"
									placeholder={t('form.passwordComponent.messages.newPassword').toString()}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								{formik.touched.newPassword && formik.errors.newPassword && (
									<div className="mt-3 text-danger fw-bold">
										<span role="alert">{t(formik.errors.newPassword)}</span>
									</div>
								)}
							</div>
							<div className="col-12">
								<label className="required form-label">{t('form.passwordComponent.confirmNewPassword')}</label>
								<input
									type="password"
									name="confirmNewPassword"
									className="form-control form-control-lg form-control-solid"
									placeholder={t('form.passwordComponent.messages.confirmNewPassword').toString()}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								{formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
									<div className="mt-3 text-danger fw-bold">
										<span role="alert">{t(formik.errors.confirmNewPassword)}</span>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="modal-footer d-flex justify-content-end py-6 px-9">
						<button type="button" className="btn btn-light btn-active-light-primary me-2" onClick={props.handleClose}>
							{t('crud.update.buttonCancel')}
						</button>
						<SaveButton type="submit" loading={isLoading} />
					</div>
				</form>
			</div>
		</Modal>
	);
}
